import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CookieService } from './cookie.service';
import { BehaviorSubject, from, Observable, of, switchMap } from 'rxjs';
import { AuthResponseModel } from '../../shared/model/auth-response.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  private readonly backendUrl = environment.backendUrl;

  private readonly authEndPoint = `${this.backendUrl}/api/auth`;

  private readonly loginEndpoint = 'login';

  private readonly validateTokenEndpoint = 'validate-token';

  private isUserConnected$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
  ) {}

  public login(
    username: string,
    password: string,
  ): Observable<AuthResponseModel> {
    return this.httpClient.post<AuthResponseModel>(
      `${this.authEndPoint}/${this.loginEndpoint}`,
      {
        username,
        password,
      },
    );
  }

  public validateToken(token: string): Observable<AuthResponseModel> {
    return this.httpClient.post<AuthResponseModel>(
      `${this.authEndPoint}/${this.validateTokenEndpoint}`,
      null,
      { params: new HttpParams({ fromObject: { token } }) },
    );
  }

  public isUserAuthenticated(): Observable<AuthResponseModel | null> {
    return from(this.cookieService.getCookie('token')).pipe(
      switchMap((token) => {
        if (!token) {
          this.isUserConnected$.next(false);
          return of(null);
        }
        return this.validateToken(token);
      }),
      catchError(() => of(null)),
    );
  }

  public getIsUserConnected() {
    return this.isUserConnected$.asObservable();
  }

  public setIsUserConnected(isUserConnected: boolean) {
    return this.isUserConnected$.next(isUserConnected);
  }
}
