import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  private readonly isNativePlatform: boolean;

  constructor() {
    this.isNativePlatform = Capacitor.isNativePlatform();
  }

  async setCookie(
    name: string,
    value: string,
    expireDays: number = 2,
    path: string = '/',
  ) {
    if (this.isNativePlatform) {
      await Preferences.set({
        key: name,
        value: value,
      });
    } else {
      let d = new Date();
      d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
      let expires = `expires=${d.toUTCString()}`;
      let cpath = path ? `; path=${path}` : '';
      document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }
  }

  async getCookie(name: string): Promise<string> {
    if (this.isNativePlatform) {
      const { value } = await Preferences.get({ key: name });
      return value ?? '';
    } else {
      let ca = document.cookie.split(';');
      let caLen = ca.length;
      let cookieName = `${name}=`;
      let c: string;

      for (let i = 0; i < caLen; i++) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
          return c.substring(cookieName.length, c.length);
        }
      }
      return '';
    }
  }

  async deleteCookie(name: string) {
    if (this.isNativePlatform) {
      await Preferences.remove({ key: name });
    } else {
      await this.setCookie(name, '', -1);
    }
  }
}
